<script setup lang="ts">
import {
	type ProductsData,
	type ProductsResponseSchema,
} from '@laam/cms-shared';

import {
	computed,
	onBeforeMount,
	onBeforeUnmount,
	onServerPrefetch,
	ref,
} from 'vue';
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '../base';
import { useState } from 'nuxt/app';
import { parseAbsoluteToLocal } from '@internationalized/date';
import ProductCard from './ProductCard.vue';

interface Props {
	metadata: ProductsData;
	data: ProductsResponseSchema;
	sectionIndex: number;
	overrideItemToShowMobile?: number;
	handleFinalSlideClick?: () => void;
}

interface Timer {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
}

const showCarousel = useState('showCarousel', () => true);

const props = defineProps<Props>();

const countDown = useState<Timer>('countDown', () => ({
	days: 0,
	hours: 0,
	minutes: 0,
	seconds: 0,
}));

const interval = ref(1000);

const timer = () => {
	if (!props.metadata.timer) return;

	const now = new Date().getTime();
	const endTime = parseAbsoluteToLocal(props.metadata.timer.endDateTime);

	const timeDiff = endTime.toDate().getTime() - now;

	if (timeDiff > 0) {
		countDown.value.days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
		countDown.value.hours = Math.floor(
			(timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
		);
		countDown.value.minutes = Math.floor(
			(timeDiff % (1000 * 60 * 60)) / (1000 * 60),
		);
		countDown.value.seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
	} else {
		showCarousel.value = false;
	}
};

const isTimerRunning = computed(() => {
	if (!props.metadata.timer) return false;

	if (
		parseAbsoluteToLocal(props.metadata.timer.startDateTime)
			.toDate()
			.getTime() < new Date().getTime() &&
		new Date().getTime() <
			parseAbsoluteToLocal(props.metadata.timer.endDateTime).toDate().getTime()
	)
		return true;
	else return false;
});

onServerPrefetch(() => {
	if (
		props.metadata.timer &&
		props.metadata.timer.enabled &&
		props.metadata.timer.startDateTime !== '' &&
		props.metadata.timer.endDateTime !== ''
	) {
		if (isTimerRunning.value) {
			timer();
			showCarousel.value = true;
		} else showCarousel.value = false;
	} else {
		showCarousel.value = true;
	}
});

onBeforeMount(() => {
	if (
		props.metadata.timer &&
		props.metadata.timer.enabled &&
		props.metadata.timer.startDateTime !== '' &&
		props.metadata.timer.endDateTime !== ''
	) {
		if (isTimerRunning.value) {
			setInterval(timer, interval.value);
			showCarousel.value = true;
		} else showCarousel.value = false;
	} else {
		showCarousel.value = true;
	}
});

onBeforeUnmount(() => {
	clearInterval(interval.value);
});
</script>

<template>
	<div
		v-if="showCarousel"
		class="gap-3xl lg:px-0 px-3xl flex flex-col items-center"
	>
		<slot name="header">
			<div
				class="flex gap-3xl w-full"
				:class="{
					'flex-col': metadata.alignment === 'center',
					'flex-row justify-between': metadata.alignment === 'left',
				}"
			>
				<div
					class="gap-sm flex flex-col grow w-full"
					:class="{
						'items-start': metadata.alignment === 'left',
						'items-center': metadata.alignment === 'center',
					}"
				>
					<h2 class="text-lg lg:display-xs font-semibold text-gray-900">
						{{ metadata.title }}
					</h2>
				</div>

				<div v-if="isTimerRunning" class="countdown-wrapper">
					<h5 class="mr-[1px] text-sm font-medium text-gray-900">Ending in</h5>
					<div class="day digit-span">{{ countDown.days }}</div>
					:
					<div class="hours digit-span">{{ countDown.hours }}</div>
					:
					<div class="minutes digit-span">{{ countDown.minutes }}</div>
					:
					<div class="seconds digit-span">{{ countDown.seconds }}</div>
				</div>
			</div>
		</slot>
		<Carousel
			class="cms--carousel-products w-full"
			:opts="{
				align: 'start',
				skipSnaps: true,
				duration: 15,
			}"
		>
			<CarouselContent>
				<CarouselItem
					v-for="(card, horizontalIndex) in data.products"
					:key="horizontalIndex"
					class="basis-[45%] md:basis-1/4"
				>
					<slot :product="card" :horizontal-index="horizontalIndex">
						<ProductCard :data="card" :index="horizontalIndex" />
					</slot>
				</CarouselItem>
			</CarouselContent>
			<CarouselPrevious />
			<CarouselNext />
		</Carousel>
	</div>
</template>
<style scoped>
.countdown-wrapper {
	@apply flex items-center gap-xxs text-sm font-semibold text-[#ea0101]  lg:gap-xl lg:text-xl;
}

.countdown-wrapper h5 {
	@apply whitespace-nowrap font-normal text-gray-900;
}

.countdown-wrapper .digit-span {
	@apply flex w-[28px] min-w-[28px] items-center justify-center bg-[#f6f6f6] px-xxs py-xs  text-sm lg:w-[40px] lg:min-w-[40px] lg:px-sm lg:text-xl;
}
</style>
